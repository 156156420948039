
if (window.innerWidth < 768) {
  const hamburger = document.querySelector('.header__hamburger')
  const nav = document.querySelector('.header__nav')
  const headerItem = document.querySelectorAll('.header__item');
  const body = document.querySelector('body');
  if (hamburger) {
    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('is-active');
      nav.classList.toggle('is-active');
      if (hamburger.classList.contains('is-active')) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    });
  }

  if (headerItem) {
    headerItem.forEach(item => {
      item.addEventListener('click', () => {
        hamburger.classList.remove('is-active');
        nav.classList.remove('is-active');
        body.style.overflow = 'auto';
      })
    })
  }
}

const businessId = '17841461847916442';
const accessToken = 'EAAEyOPquZCdwBOx35tVDrZCoAUCXcppsJ1RBDnsSZAdGxSPmvuwexwZByASqgJKXtscovoesvRfH3SUqd4uPDVZCSWBNv7h1Um1YrHj4G5Qc2dhbIqM3aPMlmRm57Q7C3ejpzPZCWQfc0TyLpllsD5chRnt9buaTLSqeJn9ZApf9w7CZA5Qztk1REjLmqXpIolWZB1Xbr8AcZD';
document.addEventListener('DOMContentLoaded', function () {
  // XMLHttpRequestのインスタンス生成
  const xhr = new XMLHttpRequest();
  const url = `https://graph.facebook.com/v17.0/${businessId}?fields=name,media.limit(9){caption,media_url,thumbnail_url,permalink,media_type}&access_token=${accessToken}`;

  xhr.open('GET', url, true);

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const json = JSON.parse(xhr.responseText);
        const instagram = document.querySelector(".instagram");
        let html = '';
        if (json.media) {
          const { data } = json.media;
          html += '<ul class="instagram__list">';

          for (let i = 0; i < data.length; i++) {
            let media_type = data[i].media_type;
            if (media_type == "IMAGE" || media_type == "CAROUSEL_ALBUM") {
              html += '<li><a href="' + data[i].permalink + '" target="_blank" rel="noopener noreferrer"><img src="' + data[i].media_url + '" alt=""></span></a></li>';
            } else if (media_type == "VIDEO") {
              html += '<li><a href="' + data[i].permalink + '" target="_blank" rel="noopener noreferrer"><img src="' + data[i].thumbnail_url + '" alt=""></span></a></li>';
              media_type = '';
            }
          }

          html += '</ul>';
        }
        instagram.innerHTML = html;
      } else {
        console.error('Instagram APIのリクエスト中にエラーが発生しました');
      }
    }
  };

  xhr.send();
});
